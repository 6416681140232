<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    @mounted="dialogMounted"
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="closeDialog">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Settings</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn dark text @click="closeDialog"> Save </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <!-- <v-form ref="editForm" v-model="valid">
        <v-text-field
          v-model="editedItem.key"
          label="Key"
          required
        ></v-text-field>
        <v-text-field
          v-model="editedItem.title"
          label="Title"
          required
        ></v-text-field>
        <v-checkbox
          label="Active"
          required
          v-model="editedItem.status"
          :true-value="'1'"
          :false-value="'0'"
        ></v-checkbox>
      </v-form> -->

      <v-data-table
        :loading="loading"
        loading-text="Loading... Please wait"
        :headers="headers"
        :items="editedItem"
        :search="search"
      >
      </v-data-table>
      <v-spacer></v-spacer>
      <v-btn color="blue darken-1" text @click="closeDialog"> Cancel </v-btn>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: {
    editedItem: {
      type: Object,
      required: true,
    },
    dialog: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      valid: false,
      headers: [
        { text: "Sales Employee Name", value: "CardName" },
        { text: "Actions", value: "CardCode" },
      ],
    };
  },

  methods: {
    closeDialog() {
      this.$emit("close");
    },
  },

  created() {
    console.log("created");
  },
};
</script>